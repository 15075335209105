<template>
  <form @submit.prevent="submit" id="form-field" class="login-form">
    <page :data="pageData" :is-child="_isChild">
      <template #cmslefttype(form)="{data: d}">
        <all-field :data="d.Data" @error="setErrors"></all-field>
          <div class="flex flex-col w-full justify-end">
            <b-button class="w-full my-4" type="is-login" native-type="submit" :disabled="hasError">{{d.SubmitButton || "Save"}}</b-button>
            </div>
      </template>
    </page>
  </form>
</template>

<script>
import FormPage from './base/FormPage.vue'
export default {
  extends: FormPage,
  methods: {
    async submit () {
      const loading = this.$buefy.loading.open()
      try {
        const data = this.getData('form-field')
        await this.$portalApi.post('/auth/resetpassword', data)
        this.$buefy.dialog.alert({
          title: 'Success',
          message: 'Your password has been reset!',
          type: 'is-danger',
          onConfirm: () => {
            this.$router.replace('/login')
          }
        })
      } catch (err) {
        this.handleSubmitError(err)
      } finally {
        loading.close()
      }
    }
  }
}
</script>

<style>

</style>
